<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-margin uk-width-1-4">
            <button class="uk-button uk-button-default uk-width-expand" style="background:white" type="button">{{filterDate}}</button>
                <div id="date-dropdown" uk-dropdown="mode: click;pos: right-top">
                    <ul class="uk-dropdown-grid uk-child-width-auto" uk-grid>
                        <div>
                            <datetime
                                    id="start_date"
                                    input-class="uk-button"
                                    v-model="meta.start_date"
                                    :max-datetime="new Date().toISOString()"
                                    placeholder="Start Date"
                                    @select="getInitPageData()"
                                ></datetime>
                        </div>
                        <template v-if="meta.start_date">
                            <div class="uk-text-center uk-margin-auto-vertical">-</div>
                            <div>
                                <datetime
                                        id="end_date"
                                        input-class="uk-button"
                                        v-model="meta.end_date"
                                        :min-datetime="meta.start_date"
                                        :max-datetime="new Date().toISOString()"
                                        placeholder="End Date"
                                    ></datetime>
                            </div>
                            <div>
                                <button class="uk-button uk-button-primary uk-width-auto" type="button" v-if="meta.start_date" @click="setDate()">Apply</button>
                            </div>
                        </template>
                    </ul>
                </div>
            <button class="uk-button-small uk-button-danger uk-width-auto" type="button" v-if="meta.start_date" @click="resetDate()"><span uk-icon="icon: close"></span></button>
        </div>

        <div class="uk-flex uk-flex-between uk-width-expand uk-margin">
            <div class="uk-flex uk-flex-between uk-width-1-4">
                <div class="uk-width-1-3">
                    <select class="uk-select" v-model="meta.search_by" @change="getInitPageData()">
                        <option :value="null" disabled>Search By</option>
                        <option value="title">Judul</option>
                        <option value="role_name">Job Title</option>
                        <option value="company_name">Company</option>
                        <option value="office_name">Job Title</option>
                    </select>
                </div>
                <div class="uk-width-1-2">
                    <input class="uk-input" type="text" v-model="meta.search" :disabled="!meta.search_by" placeholder="Keyword" @keypress.enter="getInitPageData()">
                </div>
            </div>
            <div class="uk-flex uk-flex-bottom uk-width-auto uk-text-right uk-text-bold">
                Total: {{page_data.totalDocs}} Jobs
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <div uk-dropdown="mode: click;pos: top-right" id="progress-dropdown" uk-toggle="false">
                <ul class="uk-nav uk-dropdown-nav">
                    <li>
                        <button class="uk-button uk-button-default uk-border-rounded" type="button" @click="changeSort('count_manpower')">
                            Kebutuhan
                            <span uk-icon="triangle-up" v-if="meta.sort_by == 'count_manpower' && meta.sort == 'asc'"></span>
                            <span uk-icon="triangle-down" v-if="meta.sort_by == 'count_manpower' && meta.sort == 'desc'"></span>
                        </button>
                        <button class="uk-button uk-button-default uk-border-rounded" type="button" @click="changeSort('count_applicant')">
                            Applicant
                            <span uk-icon="triangle-up" v-if="meta.sort_by == 'count_applicant' && meta.sort == 'asc'"></span>
                            <span uk-icon="triangle-down" v-if="meta.sort_by == 'count_applicant' && meta.sort == 'desc'"></span>
                        </button>
                        <button class="uk-button uk-button-default uk-border-rounded" type="button" @click="changeSort('count_fulfilled_manpower')">
                            Diterima
                            <span uk-icon="triangle-up" v-if="meta.sort_by == 'count_fulfilled_manpower' && meta.sort == 'asc'"></span>
                            <span uk-icon="triangle-down" v-if="meta.sort_by == 'count_fulfilled_manpower' && meta.sort == 'desc'"></span>
                        </button>
                    </li>
                </ul>
                </div>
                <div uk-dropdown="mode: click;pos: top-right" id="keterangan-dropdown" uk-toggle="false">
                    <ul class="uk-nav uk-dropdown-nav">
                        <li>
                            <button class="uk-button uk-button-default uk-border-rounded" type="button" @click="changeSort('count_view')">
                                Views
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'count_view' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'count_view' && meta.sort == 'desc'"></span>
                            </button>
                            <button class="uk-button uk-button-default uk-border-rounded" type="button" @click="changeSort('count_pwa_shared')">
                                PWA Shared
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'count_pwa_shared' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'count_pwa_shared' && meta.sort == 'desc'"></span>
                            </button>
                            <button class="uk-button uk-button-default uk-border-rounded" type="button" @click="changeSort('count_save')">
                                Bookmark
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'count_save' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'count_save' && meta.sort == 'desc'"></span>
                            </button>
                            <button class="uk-button uk-button-default uk-border-rounded" type="button" @click="changeSort('count_marketing_shared')">
                                Markt. Shared
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'count_marketing_shared' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'count_marketing_shared' && meta.sort == 'desc'"></span>
                            </button>
                        </li>
                    </ul>
                </div>
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped uk-text-center">
                    <thead>
                        <tr>
                            <th class="uk-text-center uk-width-medium sortable" @click="changeSort('title')">
                                Judul
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'title' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'title' && meta.sort == 'desc'"></span>
                            </th>
                            <th class="uk-text-center uk-width-small sortable" @click="changeSort('role_name')">
                                Job Title
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'role_name' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'role_name' && meta.sort == 'desc'"></span>
                            </th>
                            <th class="uk-text-center uk-width-1-6 sortable" @click="changeSort('company_name')">
                                Company
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'company_name' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'company_name' && meta.sort == 'desc'"></span>
                            </th>
                            <th class="uk-text-center sortable" @click="changeSort('office_name')">
                                Office
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'office_name' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'office_name' && meta.sort == 'desc'"></span>
                            </th>
                            <th class="uk-text-center uk-table-expand sortable" uk-toggle="target: #progress-dropdown; mode: click">
                                Progress
                                <span uk-icon="triangle-up"
                                v-if="(meta.sort_by == 'count_manpower' || meta.sort_by == 'count_applicant' || meta.sort_by == 'count_fulfilled_manpower')
                                && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down"
                                v-if="(meta.sort_by == 'count_manpower' || meta.sort_by == 'count_applicant' || meta.sort_by == 'count_fulfilled_manpower')
                                && meta.sort == 'desc'"></span>
                            </th>
                            <th class="uk-text-center uk-table-expand sortable" uk-toggle="target: #keterangan-dropdown; mode: click">
                                Keterangan
                                <span uk-icon="triangle-up"
                                v-if="(meta.sort_by == 'count_view' || meta.sort_by == 'count_pwa_shared' || meta.sort_by == 'count_save' || meta.sort_by == 'count_marketing_shared')
                                && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down"
                                v-if="(meta.sort_by == 'count_view' || meta.sort_by == 'count_pwa_shared' || meta.sort_by == 'count_save' || meta.sort_by == 'count_marketing_shared')
                                && meta.sort == 'desc'"></span>
                            </th>
                            <th class="uk-text-center uk-table-expand sortable" @click="changeSort('created_at')">
                                Created At
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'created_at' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'created_at' && meta.sort == 'desc'"></span>
                            </th>
                            <th class="uk-text-center uk-width-1-5">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="7"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(jobs, index) in page_data.docs" :key="index">
                            <td>{{ jobs.title }}</td>
                            <td>{{ jobs.role_name }}</td>
                            <td>{{ jobs.company_name }}</td>
                            <td>{{ jobs.office_name }}</td>
                            <td>
                                <p>Kebutuhan : {{ jobs.count_manpower }}</p>
                                <p>Applicant : {{ jobs.count_applicant }}</p>
                                <p>Diterima : {{ jobs.count_fulfilled_manpower}}</p>
                            </td>
                            <td>
                                <p>Views : {{ jobs.count_view }}</p>
                                <p>PWA Shared : {{ jobs.count_pwa_shared }}</p>
                                <p>Bookmarked : {{ jobs.count_save }}</p>
                                <p>Markt. Shared : {{ jobs.count_marketing_shared }}</p>
                            </td>
                            <td>{{ getDate(jobs.created_at) }}</td>
                            <td>
                                <button class="uk-button uk-button-default" type="button">Actions</button>
                                    <div uk-dropdown="mode: click">
                                        <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                            <li><a @click="goToDetails(jobs.job_id)">Details</a></li>
                                            <li class="uk-nav-divider"></li>
                                            <li><a
                                            v-clipboard:copy="`${pwaUrl}${jobs.seo_url}`" v-clipboard:success="onCopy"
                                            @click="shareJob(jobs.job_id)"
                                            >Copy Link</a></li>
                                        </ul>
                                    </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="7"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import { Datetime } from 'vue-datetime';
import {
    notificationSuccess,
} from '@/utils/notification';
import formatter from "@/utils/formatter";

export default {
    data() {
        return {
            meta: {
                search_by: null,
                search: '',
                start_date: null,
                end_date: null,
                sort_by: 'created_at',
                sort: 'desc',
                limit: 50,
                page: 1
            },
            page_data: {docs: [], totalDocs: 0},
            isLoading: true
        };
    },
    components: {
        Pagination,
        EmptyTable,
        LoadingTable,
        Datetime
    },
    computed: {
        pwaUrl(){
            if (process.env.NODE_ENV === 'production') {
                return "https://app.myrobin.id/loker/";
            } else {
                return "https://app.myrobin.tech/loker/";
            }
        },
        filterDate(){
            if (!this.meta.start_date) {
                return 'Filter Tanggal';
            } else if (!this.meta.end_date) {
                return formatter.dateComplete(this.meta.start_date);
            } else {
                return formatter.dateComplete(this.meta.start_date) + ' - ' + formatter.dateComplete(this.meta.end_date);
            }
        }
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getJobReport: 'report/getJobReport',
            shareJobMarketing: 'report/shareJobMarketing',
        }),
        async getInitPageData() {
            this.isLoading = true;
            this.page_data = await this.getJobReport(this.meta);
            this.isLoading = false;
        },
        async setDate() {
            await this.getInitPageData();
            await window.UIkit.dropdown('#date-dropdown').hide();
        },
        async resetDate() {
            this.meta.start_date = null;
            this.meta.end_date = null;
            await this.getInitPageData();
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async changeSort(sort_by) {
            if (this.meta.sort_by != sort_by) {
                this.meta.sort_by = sort_by;
            } else {
                this.meta.sort = this.meta.sort == 'desc' ?  'asc' : 'desc';
            }
            await this.getInitPageData();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        async shareJob(job_id) {
            await this.shareJobMarketing(job_id);
        },
        getDate(data) {
            return formatter.dateComplete(data);
        },
        goToDetails(job_id){
            this.$router.push(`/admin/marketing/jobs/detail/${job_id}`);
        },
    }
};
</script>

<style scoped>
.sortable {
    cursor:pointer
}
</style>
